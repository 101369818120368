<template>
  <offer-form :base-offer="offer" view-mode="duplicate" @reload="getOffer()" />
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { COUNTRY_BY_ID, GET_COUNTRIES_REQUEST } from '@/store/modules/country/keys';
import { CREATE_TOAST } from '@/store/modules/toast/keys';
import { Toast } from '@/model/shared/Toast';
import { list as offerListRoute } from '@/router/private/modules/ecommerce/demand/offer/list';
import { getOfferById } from '@/services/modules/ecommerce/offer';
import metaInfo from '@/mixins/common/metaInfo';

import OfferForm from '@/components/organisms/modules/ecommerce/offer/form/OfferForm';
import apiRequest from '@/utils/apiRequest';

export default {
  name: 'OfferDuplicate',
  components: {
    OfferForm,
  },
  mixins: [metaInfo],
  props: {
    offerId: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    offer: null,
    retries: 0,
    MAX_RETRIES: 3,
  }),
  computed: {
    ...mapGetters({
      getCountryById: COUNTRY_BY_ID,
    }),
  },
  async created() {
    await this.getOffer();
  },
  methods: {
    ...mapActions({ getCountries: GET_COUNTRIES_REQUEST, createToast: CREATE_TOAST }),

    async getOffer() {
      await this.getCountries();

      await apiRequest(async () => {
        const { data } = await getOfferById(this.offerId);
        this.offer = data;
        this.offer.creatives = null;
        this.offer.publishers = null;
        this.offer.createdAt = null;
        this.offer.comments = null;
        this.offer.country = this.getCountryById(this.offer.country.iso3);
        this.updateTitle();
      }).catch(async () => {
        if (this.retries <= this.MAX_RETRIES) {
          this.retries++;
          setTimeout(async () => await this.getOffer(), this.TIMEOUT * this.retries);
        } else {
          this.createToast(Toast.error('Error loading offer', 'We found a issue loading your offer, try again later'));
          await this.$router.push({ name: offerListRoute.name });
        }
      });
    },
  },
};
</script>
